import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nommage et architecture CSS",
  "description": "Explications sur la façon dont je structure mon CSS pour une maintenance optimale.",
  "date": "2015-08-19T00:00:00.000Z",
  "path": "/articles/nommage-et-architecture-css/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A ma connaissance, il n’y a rien de plus casse-tête côté front que de trouver la bonne structure et le nommage correct pour assurer une maintenabilité et une lisibilité optimales de mes CSS. Certaines méthodologies, comme BEM ou SUIT, permettent cependant de faciliter ça. Je vais décrire ici la façon dont j’ai adapté ces techniques à mon workflow.`}</p>
    <h2>{`Les bases : BEM et SUIT`}</h2>
    <p>{`Si ces deux acronymes ne vous évoquent rien, je vous suggère avant toute chose de lire mon `}<a parentName="p" {...{
        "href": "/articles/css-notation-bem/"
      }}>{`article`}</a>{` sur le premier ainsi que la `}<a parentName="p" {...{
        "href": "https://github.com/suitcss/suit/blob/master/doc/naming-conventions.md"
      }}>{`documentation Github`}</a>{` du deuxième.`}</p>
    <p>{`J’ai pour ma part commencé par utiliser BEM de manière traditionnelle avant d’y incorporer une part de SUIT, ce qui me donnait quelque chose de ce genre :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.Component`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.Component-element`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.Component--modifier`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Je préfère cette syntaxe par rapport au BEM classique, où l’utilisation des `}<kbd>{`_`}</kbd>{` ne facilite pas vraiment l’édition du nom des classes.`}</p>
    <h2>{`Pousser plus loin le concept des Modifiers`}</h2>
    <p>{`Le souci avec les `}<em parentName="p">{`Modifiers`}</em>{`, c’est qu’ils créent des classes à rallonge, par exemple :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Button Button--small Button--green Button--caps`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Texte`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`J’ai récemment vu passer un article d’`}<a parentName="p" {...{
        "href": "http://www.envato.com/"
      }}>{`Envato`}</a>{` dans lequel était décrit le principe des `}<em parentName="p"><a parentName="em" {...{
          "href": "http://webuild.envato.com/blog/chainable-bem-modifiers/"
        }}>{`Chainable Modifiers`}</a></em>{`, principe qu’ils ont mis en place pour Envato Market.`}</p>
    <p>{`En se basant sur cette convention, le code ci-dessus se transformerait en :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`Button -small -green -caps`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Texte`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`C’est quand même plus agréable à lire, non ?`}</p>
    <p>{`Et si vous utilisez Sass, le mettre en place et en assurer la maintenabilité devient un jeu d’enfant.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "scss"
    }}><pre parentName="div" {...{
        "className": "scss"
      }}><code parentName="pre" {...{
          "className": "scss"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.Button `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 2rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`.-small `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`font-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1rem`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`.-green `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` green`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token selector"
          }}><span parentName="span" {...{
              "className": "token parent important"
            }}>{`&`}</span>{`.-caps `}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`text-transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` uppercase`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Autres emprunts à SUIT`}</h2>
    <p>{`J’utilise également deux autres conventions provenant de SUIT : les classes utilitaires et les classes d’état.`}</p>
    <h3>{`Classes utilitaires`}</h3>
    <p>{`Ce sont des classes génériques, identifiables par un préfixe `}<code parentName="p" {...{
        "className": "text"
      }}>{`u-`}</code>{` :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.u-white`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token selector"
          }}>{`.u-floatLeft`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
...`}</code></pre></div>
    <h3>{`Classes d’état`}</h3>
    <p>{`Celles-ci permettent d’identifier le changement d’état d’un composant; elles sont préfixées par `}<code parentName="p" {...{
        "className": "text"
      }}>{`is-`}</code>{` :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`button`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`button is-disabled`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Text`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`button`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h2>{`Sélecteurs JavaScript`}</h2>
    <p>{`Pour tous les sélecteurs que je vais cibler en JS, j’ajoute une classe (ou une id) préfixée par `}<code parentName="p" {...{
        "className": "text"
      }}>{`js-`}</code>{`. Cette classe ne doit jamais apparaître dans mes CSS afin de maintenir une séparation claire des rôles.`}</p>
    <h2>{`What’s next ?`}</h2>
    <p>{`Même si la mise en place de cette méthodologie m’a procuré un gain énorme en termes de maintenabilité et de lisibilité sur des grosses bases de code CSS, elle n’est pas infaillible, et les risques de non-isolation et de spécificité du code existent toujours.`}</p>
    <p>{`Pour contourner `}{`–`}{` entre autres `}{`–`}{` ces problèmes, `}<a parentName="p" {...{
        "href": "http://glenmaddern.com/articles/css-modules"
      }}>{`Glenn Maddern`}</a>{` a créé un système de `}<a parentName="p" {...{
        "href": "http://glenmaddern.com/articles/css-modules"
      }}>{`modules CSS`}</a>{`. Grâce à du JS, les styles sont maintenus à leur création dans un `}<em parentName="p">{`scope`}</em>{` local qui permet de résoudre les questions de nommage et d’héritage. Malheureusement, cette solution rajoute un niveau de complexité supplémentaire qui risque d’en rebuter certains.`}</p>
    <p>{`Reste a espérer qu’un standard verra enfin le jour pour corriger ce casse-tête…`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      